@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
body {
  margin: 0;
/* overflow-x: hidden !important; */




  font-family: "Cairo", sans-serif !important;
  font-optical-sizing: auto !important;
  /* overflow-x: hidden; */
}

.itemsidbar {
  padding: 3px;

  border-radius: 10px;
}

.itemsidbar a {
  color: black;
}

.itemsidbar:hover {
  background-color: dimgrey;
  cursor: pointer;
  color: white !important;
}

.itemsidbar:hover a {
  color: white;
}

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;

}

.text-color {
  color: #fbe1a5 !important;
}

.text-yellow {
  color: yellow !important;
}
.bg-color {
  background-image: linear-gradient(to right, #0d2338 0%, #175682 50%, #85bad9 100%);
  color: white !important;
}


.bg-color a {
  color: white;
}

.item-navbar {
  /* background-color: dimgrey !important; */
  color: #ceb073 !important;
  /* border-radius: 10px; */
}

.nav-link:hover {
  background-color: #0d2338 !important;
  color: white !important;
  border-radius: 5px;
}
.nav-link{
  font-size: 13px !important;
}
.infonav{
  background-color: #0d2338 !important;
  color: white !important;
  border-radius: 5px;
}

.font-23 {
  font-size: 23px;
}

.font-12 {
  color: #fcfcfc;
  font-size: 12px;
  font-weight: 100;
}

.topbar-txt {
  font-size: 12px;
  font-weight: 100;
  padding-top: 6px;
  padding-bottom: 6px;
  display: inline-block;
}

.img-nav {
  width: 177px;
}

.btnNav {
  background-color: #0d2338;
  color: white;
  border-radius: 5px !important;
  min-width: 100px; 
  height: 30px;
  font-size: 14px;
  /* padding: 10px; */
  text-align: center !important;
}

.w-select {
  width: 175px;
}

.positionhover {
  background-color: #0d23384d;
  inset: 0;
}


.carousel-caption p {
  /* color: var(--secondary-color); */
  padding-bottom: 15px;
  font-size: 22px;
  color: black !important;
  font-weight: 500;
}

.btn-color {
  background-color: #0d2338 !important;
  font-size: 15px !important;
  color: #ceb073 !important;
}
.bgsection{
  background-image: linear-gradient(to right, #0d2338 0%, #175682 50%, #85bad9 100%) !important;
}

.bg-Services {
  background-color: #2d271a;
  /* background-color: rgba(223, 163, 41, 11%); */
}

.img-services {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.feat-img {
  width: 90px;
  height: 90px;
  background-color: #ffffff;
  border-radius: 100%;
  /* margin: 30px auto; */
}

.feat-img img {
  width: 75%;
  height: 75%;
  margin: 10px;
}

.font-17 {
  font-size: 17px;
}

.service-right {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.servimg {
  width: 80px;
  height: 80px;
  background-color: #0d2338;
  border-radius: 100%;
}
.nav-h-100{
  height: 90px !important;
}

.service-right {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.service-right-side .serv-title {
  padding-left: 15px;
  padding-top: 15px;
}

.w-70 {
  width: 70%;
}

.service-big-img img {
  width: 100%;
  height: 480px;
  margin: 10px auto 0;
}

.bg-blue {
  background-color: #0d2338;
}

.google-reviews::before {
  content: "";
  background-image: url(https://eadentalclinic.com/wp-content/themes/ea-clinic/frontend/img/testi-bg.jpg);
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 100vh !important;
  /* opacity: 0.1; */
  height: 100vh;
}

.Testmonials {
  position: absolute;
  inset: 0;
  z-index: 25;
  display: block;
  height: 100vh;
  /* background: linear-gradient(200deg, #E7E7E7 10%, #EDD481A8 100%) !important; */
  background-color: transparent;
  /* background-image: linear-gradient(200deg, #E7E7E7 10%, #EDD481A8 100%) !important; */
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px !important; */

}

.z-20 {
  z-index: 25;
}

.img-icone {
  width: 90px   !important;
  height: 90px !important;
}

.img-sercel {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.h-card {
  height: 392px !important;
}

.BookAppointment {
  position: relative;
  height: auto;
  width: 100%;

  background-image: url(https://eadentalclinic.com/wp-content/themes/ea-clinic/frontend/img/home-4.jpg);
  background-size: cover;
  /* margin-bottom: 60px; */
  /* height: 100vh; */
}

.BookAppointment::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0d23384d;
  /* Adjust the opacity as needed */
  z-index: 1;
}


.BookAppointment>* {
  position: relative;
  z-index: 2;
  color: white;
  /* Set text color to white for better contrast */
}

.titleAppointment span {
  border: 1px solid black;
  width: 70%;
  display: block;
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.formAppointment {
  font-size: 12px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19);
  border-radius: unset;
  height: 37px;

}


/* .navbar-scrolled {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
} */

.fixedwhite {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  cursor: pointer;
}

.fixedwhit {
  position: fixed;
  left: 30px;
  bottom: 30px;
  visibility: visible;
  background-color: transparent;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 200000 !important;
  font-size: 25px;
  color: white;
  background-color: #0d2338;
}



/* Loading.css */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0d23384d;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* تأكد من أن الواجهة في الأعلى */
}

.loading-spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left: 8px solid #000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.logoimglogin {
  width: 300px;
  height: 100px;
  margin-bottom: 50px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



.divResponseve {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: #0d23384d; */
  height: 116px;
  z-index: 2558;

}

.iconeresponsev {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /* background-color: #0d2338; */
  font-size: 30px;
  color: white;
}


.inputdesine {
  height: 50px;
  background-color: #9999993d !important;
  border-color: #999 !important;
}

.addreviews {
  background: linear-gradient(200deg, #E7E7E7 10%, #EDD481A8 100%) !important;
  background-color: transparent;
  background-image: linear-gradient(200deg, #E7E7E7 10%, #EDD481A8 100%) !important;
  border-radius: 10px;
}

[dir='rtl'] .slick-next {
  font-size: 30px !important;
  left: 50 !important;
  background-color: #0d2338 !important;
}

[dir='rtl'] .slick-prev {
  right: 100 !important;
  border-radius: 50% !important;
  width: 50px !important;
  height: 50px !important;
  background-color: #0d2338 !important;
}

.slick-dots li button:before {
  color: white !important;
}

[dir='rtl'] .slick-next:before {
  content: '\f104' !important;
  /* أيقونة FontAwesome للسهم الأيمن */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  /* color: white transparent; */
  font-size: 24px;
  /* حجم الأيقونة */
}

[dir='rtl'] .slick-next {
  border-radius: 50% !important;
  width: 50px !important;
  height: 50px !important;
  /* color: white !important; */
}
.reviweimg{
  height: 85vh;
  border-radius: 10px;
}
.nav-link{
  color: dark;
}
@media only screen and (max-width: 768px) {
  .nav-item {
    display: flex;
    justify-content: center;
  }

  .img-nav {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  [dir='rtl'] .slick-prev {
    display: none !important;
  }

  [dir='rtl'] .slick-next {
    display: none !important;

  }
  /* .nav-link {
    color: black !important;
  } */
  .navbar-collapse{
    background-color: white !important;
    color: black !important;
  }
 
  .navbar-collapse{
    position: relative;
    z-index: 55;
  }

  /* body {
    background-color: red !important;
  } */
}
.accordion-button::after{
  margin-right: auto !important;
  margin-left: 0 !important;
}
.boxshado{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.embed-responsive-item{
  height: 320px;
}
a{
  text-decoration: none !important;
}
.sliderboxshado , .feat-img{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.navbar {
  transition: background-color 0.3s ease, padding 0.3s ease;
}

.navbar-scrolled {
  background-color: rgba(0, 0, 0, 0.8); /* Adjust color as needed */
  padding: 10px 0; /* Adjust padding for a more compact look when scrolled */
}

.navbar-scrolled {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: white;
  transition: top 0.3s ease-in-out;
}

.hide-navbar {
  top: -80px; /* */
}
.w-130{
  /* width: 130%; */

  height: 65px;
  
  
}
.w-120{
  height: 50px
  ;
}
.w-120{
  width: 100% !important;
}
.infoabout {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; /* Centers the image on all screen sizes */
  height: 50vh;
  display: flex; /* Aligns content (if any) within the section */
}

.h-sliderimg{
  height: 90vh  !important;
}
.slick-arrow{
  z-index: 25
  ;
}

@media (max-width: 768px) {
  .infoabout {
    height: 30vh; /* Adjusts height for smaller screens */
    background-size: cover; /* Ensures the background scales properly */
  }
.carousel-item , .h-sliderimg{
  height: 50vh !important;
}
.btnNav{
  width: 66px !important;
}
[dir='rtl'] .slick-prev {
  display: none !important;
}

[dir='rtl'] .slick-next {
  display: none !important;

}
.slick-next{
  display: none !important;
}
}
body{
  background-image: linear-gradient(to right, #0d2338 0%, #175682 50%, #85bad9 100%) !important;
}

.position-nav{
  left: 0;
  right: 0;
  background-color: transparent !important;;
}
.font-27{
  font-size: 27px;
}
.imgfooter img{
  border-radius: 20px ;
}
.imgabout{
  height: 70vh;
}
.inputReviews{
  height: 50px;
}


.stars {
  font-size: 2rem;
  cursor: pointer;
}
.star {
  color: #175682 ;
}
.star.filled {
  color: #f39c12;
}


.showPassword{
  /* padding: 30px ; */
  height: 48px;
  color: white !important;
}
.imgResult{
  width: 220px !important;
}